/* You can add global styles to this file, and also import other style files */
@import "./assets/styles/icons"

*
  margin: 0
  padding: 0
  font-family: 'Montserrat', sans-serif

th
  color: black !important
  font-weight: bold !important

.form-group__cost-area-item, .form__cost-area-item
  .mat-form-field-infix
    width: 120px !important

.button-user-details
  .mat-button-wrapper
    display: flex
    align-items: center

.initial-container
  .mat-sidenav-content
    position: inherit

.mat-expansion-panel
  .mat-content
    align-items: center

.date-month
    .mat-calendar-header, .mat-calendar-body-label
      display: none

.general-costs-block__card__header
  .mat-card-header-text
    display: none

.mat-progress-spinner
  margin: 0 auto
  margin-top: 10px
  margin-bottom: 10px

.crm-list-header
  .mat-card-header-text
    margin: 0

.align-header
  .mat-sort-header-container
    justify-content: center

.card-attachments
  .mat-card-header-text
    width: 100% !important

.timeentry-field
  .mat-form-field-infix
    width: 100%

.form-field-date
  height: 40px
  .mat-form-field-infix
    border: 0 !important
  .mat-form-field-wrapper
    .mat-form-field-flex
      padding: 0 !important

.form-field
  height: 50px
  .mat-form-field-infix
    border: 0 !important
  .mat-form-field-wrapper
    .mat-form-field-flex
      padding: 0 !important

.mat-typography h1, .mat-typography h2, .mat-typography h3, .mat-typography h4, .mat-typography h5, p, span, ul, ol, a
  font-family: 'Montserrat', sans-serif

.mat-toolbar .mat-form-field-underline
  background-color: white !important

mat-paginator.quotes-paginator
  .mat-paginator-outer-container
    height: 23px
    display: flex
    align-items: center
  .mat-paginator-page-size-label, .mat-paginator-page-size-value
    display: none !important

.mat-select-search-inside-mat-option .mat-select-search-input
  font-family: "Montserrat", sans-serif

body
  background-color: #a5a3a4

mat-sidenav-content
  background-color: #a5a3a4

.mat-grid-tile-content
  display: flex
  flex-direction: column

.btn-success
  background: #5cb85c
  color: white

.btn-delete
  background: #d9534f
  color: white
