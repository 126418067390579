@font-face {
  font-family: "icomoon";
  src: url("../icons/icomoon.eot?w1cj9p");
  src: url("../icons/icomoon.eot?w1cj9p#iefix") format("embedded-opentype"),
    url("../icons/icomoon.ttf?w1cj9p") format("truetype"),
    url("../icons/icomoon.woff?w1cj9p") format("woff"),
    url("../icons/icomoon.svg?w1cj9p#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-red-flag:before {
  content: "\e92e";
  color: #d80e40;
}
.icon-finance:before {
  content: "\e92d";
}
.icon-calendar:before {
  content: "\e92a";
}
.icon-copy:before {
  content: "\e92b";
}
.icon-envelope-check:before {
  content: "\e92c";
}
.icon-filter:before {
  content: "\e924";
}
.icon-flag:before {
  content: "\e925";
}
.icon-file-download:before {
  content: "\e926";
  color: #0E5FD8;
}
.icon-add-file:before {
  content: "\e927";
  color: #fff;
}
.icon-bill:before {
  content: "\e928";
  color: #dd636e;
}
.icon-invoice:before {
  content: "\e929";
  color: #0e5fd8;
}
.icon-expand-up:before {
  content: "\e921";
  color: #400ed8;
}
.icon-expand-down:before {
  content: "\e920";
  color: #400ed8;
}
.icon-done:before {
  content: "\e91f";
}
.icon-permissions:before {
  content: "\e91e";
  color: #fff;
}
.icon-mailing:before {
  content: "\e91d";
  color: #fff;
}
.icon-general-costs:before {
  content: "\e91c";
  color: #fff;
}
.icon-paste:before {
  content: "\e919";
}
.icon-sub-arrow:before {
  content: "\e91b";
  color: #7d8592;
}
.icon-clipboard:before {
  content: "\e918";
}
.icon-eye-hide:before {
  content: "\e917";
  color: #fff;
}
.icon-eye:before {
  content: "\e915";
  color: #fff;
}
.icon-plus:before {
  content: "\e916";
  color: #fff;
}
.icon-quotes:before {
  content: "\e900";
  color: #fff;
}
.icon-excel:before {
  content: "\e912";
}
.icon-pdf:before {
  content: "\e913";
}
.icon-word:before {
  content: "\e914";
}
.icon-file-add:before {
  content: "\e90d";
  color: #400ed8;
}
.icon-close:before {
  content: "\e911";
}
.icon-save:before {
  content: "\e90e";
  color: #400ed8;
}
.icon-diploma:before {
  content: "\e90f";
  color: #400ed8;
}
.icon-add:before {
  content: "\e910";
  color: #400ed8;
}
.icon-file-psd:before {
  content: "\e901";
  color: #bdc0c8;
}
.icon-trash:before {
  content: "\e902";
  color: #400ed8;
}
.icon-cloud-share:before {
  content: "\e903";
  color: #400ed8;
}
.icon-cloud-download:before {
  content: "\e904";
  color: #400ed8;
}
.icon-edit:before {
  content: "\e905";
  color: #fff;
}
.icon-folder-add:before {
  content: "\e906";
}
.icon-settings:before {
  content: "\e907";
  color: #fff;
}
.icon-contacts:before {
  content: "\e908";
  color: #fff;
}
.icon-reports:before {
  content: "\e909";
  color: #fff;
}
.icon-crm:before {
  content: "\e91a";
  color: #fff;
}
.icon-staff:before {
  content: "\e90a";
  color: #fff;
}
.icon-projects:before {
  content: "\e90b";
  color: #fff;
}
.icon-timeentry:before {
  content: "\e90c";
  color: #fff;
}
.icon-lock-open:before {
  content: "\e922";
}
.icon-lock:before {
  content: "\e923";
}
